import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBjdsN2461T5eMDtUZCy-hNcRHAxtGSHA0",
  authDomain: "pico-cad87.firebaseapp.com",
  projectId: "pico-cad87",
  storageBucket: "pico-cad87.appspot.com",
  messagingSenderId: "686990291043",
  appId: "1:686990291043:web:959d377b441cb972764856",
  measurementId: "G-MTN5R9BLES"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
  
export default db;