import './Sprint.css'
import SprintCalendar from './SprintCalendar'

function Sprint(props) {
    return (
        <div className="rounded ml-2 mr-2 pl-3 pr-3 mb-4 shadow-lg card">
            <h1 className="flex justify-between mt-2 mb-4 text-xl">
                <span className="text-[#555555]">{props.name}</span>
                <span className="text-[#555555]">{props.month}</span>
            </h1>

            <SprintCalendar start={props.start} end={props.end} />
        </div>
    );
}

export default Sprint;