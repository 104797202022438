import './SprintCalendar.css'
import { getDates, isToday } from './Date'

function SprintCalendar(props) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const startDate = new Date(props.start);
    const endDate = new Date(props.end);
    const dates = getDates(startDate, endDate);

    return (
        <div>
            <div className="wrapper text-center">
                {
                    days.map((day) => {
                        const isWeekend = day === 'Sat' || day === 'Sun';

                        return (
                            <span key={day} className="text-xs ml-2 mr-2">
                                <div className={isWeekend ? 'text-[#adafb0]' : 'text-[#555555]'}>{day}</div>
                            </span>
                        )
                    })
                }
            </div>
            <hr className="my-1 h-px card-hr border-0 dark:bg-gray-700" />
            <div className="wrapper text-center mt-2 mb-2">
                {
                    dates.map((date) => {
                        const day = date.getUTCDay();
                        const isWeekend = day === 0 || day === 6;

                        return (
                            <div key={date} className={isToday(date) ? 'today' : null}>
                                <div className="w-12 h-12 lh-12 leading-10">
                                    <div className={isWeekend ? 'text-[#adafb0]' : 'text-[#555555]'}>{date.getUTCDate()}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default SprintCalendar;