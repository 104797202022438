import React, { useState, useEffect } from "react";
import './App.css'
import PIProgress from './PIProgress';
import Sprint from './Sprint.js'
import { getDates, getMonthName } from './Date'
import db from "./firebase";
import { doc, getDoc } from "firebase/firestore";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const ref = doc(db, "pi", "latest");
      const docSnap = await getDoc(ref);
      setData(docSnap.data());
    }

    fetchData();
  }, []);

  if (data === null) {
    return;
  }

  const firstDay = data.sprints[0].startDate.toDate();
  const lastDay = data.sprints.slice(-1)[0].endDate.toDate();
  const range = getDates(firstDay, lastDay);

  return (
    <div className="App">
      <div className="title">
        <div>Pico</div>
      </div>

      <div className="shadow-md bg-[#e8ecee]">
        <h1 className="bg-[#e8ecee] pt-4 pb-2 text-center text-4xl text-[#555555]">{data.title}</h1>
        <PIProgress range={range} />
      </div>

      <div className="details mt-4 mx-5">
        {data.sprints.map((d) => <Sprint key={d.title} name={d.title} start={d.startDate.toDate()} end={d.endDate.toDate()} month={getMonthName(d.startDate.toDate())} />)}
      </div>
    </div>
  );
}

export default App;
