// https://gist.github.com/miguelmota/7905510
export const getDates = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;

    const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setUTCDate(date.getUTCDate() + days);
        return date;
    }

    while (true) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)

        if (areDatesEqual(currentDate, endDate)) {
            dates.push(currentDate)
            break;
        }
    }

    return dates;
}

const areDatesEqual = (date1, date2) => {
    return date1.getUTCFullYear() === date2.getUTCFullYear() && date1.getUTCMonth() === date2.getUTCMonth() && date1.getUTCDate() === date2.getUTCDate();
}

export const isToday = (date) => {
    const today = new Date();
    return today.getUTCFullYear() === date.getUTCFullYear() && today.getUTCMonth() === date.getUTCMonth() && today.getUTCDate() === date.getUTCDate();
}

export const getMonthName = (date) => {
    const names = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    return names[date.getMonth()];
}