import {isToday} from './Date'

function PIProgress(props) {
    const days = props.range.length - 1;
    const today = props.range.findIndex(day => isToday(day));
    const percentage = today === -1 ? 0 : Math.round(100 * today / days);

    return (
        <div className="mb-5 h-8 pt-2">
            <div className="text-center ml-10 mr-10 rounded-full bg-[#c3cbd0] h-3">
                <div style={{ width: `${percentage}%` }}>
                    <div className="text-center rounded-full bg-[#41aea6] h-3" />
                </div>
            </div>
        </div>
    )
}

export default PIProgress;